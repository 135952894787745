import React from "react";

function Tittle(props){
    return(
        <>
        <div className="headings mt-3 container"><p>{props.tittle} &gt;</p></div>
        </>
    )
}

export default Tittle;