const Images = {
  logo: "https://1.bp.blogspot.com/-98FLdlCRUd0/YWKOXC4rC1I/AAAAAAAAA48/6TOa-g8_AXA96ZUf0wnQZk-xTExGgRNzACLcBGAsYHQ/s150/hkr%2Bagri%2Btechs%2Blogo%2Btamil.png",

  profile:
    "https://lh3.googleusercontent.com/-9a6Avi6qiPA/YQoPPwbU29I/AAAAAAAACJ0/hbRnzLfxmgcOY3zaZ0Wgw3mAA3pEHK-7ACLcBGAsYHQ/Annotation%2B2021-04-11%2B072249.png",

  sample:
    "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiT7NPS1lkPkvtx5zMCPpZKVRQ2BqELg8ZeGtg8Z9vrLM99I7Wt120Lr9pg-8THQVje3hUBbkDydQ7UrU3UGnZJcIb3vicy2Zlo-8OnWL0ZdRmBzT_i_88zB-K4gh_Va8c595nJLuIt2eo9IspUODyKz1v9-13XQYNixWpzaAs4GJvaJSB1tB6x7yCM8g/s16000/firefox%20focus%20take%20your%20privacy%20to%20next%20level.jpg",

  aboutBanner:
    "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiIR9DMEbkKrB7UwbstGcqVQ-1An4rn8K2F8oWlgjogdKD7QiHwUDBOHYtjbIwR_tx9tOTh7ar0QB1tdrmgBV_pNu8PAiJMGz40IL3kLiLNsXUNQber4U70H-LDlscWvHwpqLeL7DJ8uSVJeMDUM24OfJ4qiDA6ggn3lB8zC2OKc4daLx0vTcb4RF4l/s16000/moovendhan_linkedin_dark.png",
};

export default Images;
